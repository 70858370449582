import React from "react";
import { Link, PageProps } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import PageTitle from "../components/PageTitle";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SignUpForm from "../components/SignUpForm";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import SignUp from "./signup";
import Section from "../components/Section";
import IconCard from "../components/IconCard";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 700,
            margin: "0 auto",
            paddingBottom: 60,
        },
    })
);

const IndexPage: React.FC<PageProps> = (props) => {
    const classes = useStyles();
    return (
        <Layout>
            <SEO title="Timothy Edgar" path={props.path} />
            <Section first content>
                <PageTitle title="Hi, I'm Tim." />

                <Typography variant="body2">
                    I'm an engineer, entrepreneur, and proud father of two boys.
                    I am also the cofounder and Chief Technology Officer (CTO)
                    of <a href="https://www.venteur.co">Venteur</a>, a startup
                    tackling health insurance for the self-employed.
                </Typography>
            </Section>
            <Section content>
                {/* <Box textAlign="center">
                    <h2>Learn more</h2>
                </Box> */}
                <Box justifyContent="space-evenly" display="flex">
                    <IconCard
                        icon={AccountCircleIcon}
                        title="About Me"
                        to="/about"
                    />
                    <IconCard
                        icon={LibraryBooksIcon}
                        title="My Writing"
                        to="/blog"
                    />
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs></Grid>
                    <Grid item xs></Grid>
                </Grid>
            </Section>
            <Section content last>
                <SignUpForm elevation={0} spacing={0} />
            </Section>
        </Layout>
    );
};

export default IndexPage;
