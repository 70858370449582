import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { Link } from "gatsby";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import SvgIcon from "@material-ui/core/SvgIcon";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            textDecoration: "none",
        },
        card: {
            width: "100%",
            maxWidth: 300,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(2),
        },
        cardImage: {
            paddingBottom: 0,
        },
        cardDescription: {
            height: "4.2em",
            lineHeight: "1.4",
        },
    })
);

interface IconCardProps {
    title: string;
    icon: typeof SvgIcon;
    to: string;
}

const IconCard: React.FC<IconCardProps> = (props) => {
    const classes = useStyles(props);
    const [hover, setHover] = React.useState(false);
    const IconComponent = props.icon;
    return (
        <Link to={props.to} className={classes.link}>
            <Card
                className={classes.card}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                elevation={hover ? 8 : 0}
            >
                <Box textAlign="center" p={3}>
                    <IconComponent
                        fontSize="large"
                        color={hover ? "primary" : "action"}
                    />
                    <Box marginTop={1}>
                        <Typography
                            variant="h6"
                            component="span"
                            align="center"
                        >
                            {props.title}
                        </Typography>
                    </Box>
                </Box>
            </Card>
        </Link>
    );
};

export default IconCard;
